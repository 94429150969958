body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #186992;
  height: 100%;
}
html,
body,
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
}

.fade-enter .page {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active .page {
  opacity: 1;
  -webkit-transition: opacity 450ms ease-in;
  transition: opacity 450ms ease-in;
}

.transition-group {
  height: 100%;
}

.router {
  height: 100%;
}

