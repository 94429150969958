.fade-enter .page {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active .page {
  opacity: 1;
  transition: opacity 450ms ease-in;
}

.transition-group {
  height: 100%;
}

.router {
  height: 100%;
}
